/* eslint-disable */
// [[A AND B] OR [C AND D]]

export default {
  by_page: {
    // GLOBAL
    '/home': [['any']],
    '/first_login': [['view_profile', 'update_profile']],
    '/logout': [['any']],
    '/about': [['any']],

    // DATA
    '/data/overview': [['view_data']],
    '/data/account': [['view_data']],
    '/data/configure/create': [['view_data']],
    '/data/search': [['view_data']],
    '/data/explore': [['view_data']],
    '/data/:data_type/:batch_id': [['view_data']],
    '/data/create': [['create_data']],

    // DATA COLLECTIONS
    '/data/collection/create': [['view_data']],
    '/data/collection/overview': [['view_data']],
    '/data/collection/:collection_id': [['view_data']],
    '/data/collection/:collection_id/data-view/:view_id/:custom_name': [['view_data']],

    // DECISION TREE
    '/data/decision/overview': [['run_decision_tree'], ['create_decision_tree']],
    '/data/decision/run': [['run_decision_tree'], ['create_decision_tree']],
    '/data/decision/view/:results_id': [['run_decision_tree']],

    // REPORT COLLECTIONS
    '/report/collection/overview': [['view_report_collection']],
    '/report/collection/:collection_id': [['view_report_collection']],
    '/report/collection/create': [['create_report_collection']],

    // DATA MAGNETS
    '/report/data-magnet/overview': [['view_data_magnets_list']],
    '/report/data-magnet/create': [['create_data_magnet']],
    '/report/data-magnet/:data_magnet_id': [['view_data_magnet']],

    // RULESETS
    '/report/ruleset/overview': [['view_rulesets_list', 'view_report']],
    '/report/ruleset/create': [['create_ruleset']],
    '/report/ruleset/:ruleset_id': [['view_ruleset']],

    // TEMPLATES
    '/report/template/overview': [['view_report']],
    '/report/template/create': [['view_report']],
    '/report/template/:template_id': [['view_report']],

    // REPORTS
    '/report/overview': [['view_reports_list']],
    // - create
    '/report/create': [['create_report'], ['upload_report'], ['merge_report']],
    // - explore
    '/report/explore/abc123': [['view_report']],
    '/report/explore': [['view_reports_list', 'view_report']],
    // - coverage
    '/report/:report_id/coverage': [['view_report']],
    '/report/:report_id/coverage/:data_type/:batch_id': [['view_report']],
    // - detail
    '/report/:report_id': [['view_report']],
    '/report/:report_id/page/:page': [['view_report']],
    '/report-cell-compare': [['view_report']],
    // - compare
    '/report-compare': [['view_report']],
    '/report-compare/page/:page': [['view_report']],
    // - custom report
    '/report-type/create': [['view_report', 'create_report']],
    '/report/custom/overview': [['view_report', 'create_report']],
    '/report/custom/create': [['create_report']],
    '/report/custom/:report_id': [['create_report']],
    // - gabriel
    '/report/submissions': [['view_report', 'view_regulator_submissions']],
    // - validation
    '/report/validations/overview': [
      ['view_report_custom_validation_rule'],
      ['update_report_custom_validation_rule'],
      ['create_report_custom_validation_rule'],
      ['delete_report_custom_validation_rule'],
      ['review_report_custom_validation_rule'],
      ['sign_off_report_custom_validation_rule'],
    ],
    '/report/validations/:custom_validation_id': [
      ['view_report_custom_validation_rule'],
      ['update_report_custom_validation_rule'],
      ['create_report_custom_validation_rule'],
      ['delete_report_custom_validation_rule'],
      ['review_report_custom_validation_rule'],
      ['sign_off_report_custom_validation_rule'],
    ],
    '/report/validations/:custom_validation_id/duplicate': [
      ['view_report_custom_validation_rule'],
      ['update_report_custom_validation_rule'],
      ['create_report_custom_validation_rule'],
      ['delete_report_custom_validation_rule'],
      ['review_report_custom_validation_rule'],
      ['sign_off_report_custom_validation_rule'],
    ],
    '/report/validations/:custom_validation_id/edit': [['update_report_custom_validation_rule']],
    '/report/validations/create': [['create_report_custom_validation_rule']],

    // REGINA
    '/regina/analyser': [['view_data']],
    '/regina/drill-up': [['view_data']],
    '/regina/what-if': [['view_data']],
    '/regina/product-examples': [['view_data']],
    '/regina/fireman': [['view_data']],

    // LOGIC
    '/logic/overview': [['view_reports_list', 'view_logic']],
    '/logic/rules': [['view_reports_list', 'view_logic']],
    '/logic/custom-rules': [['view_custom_report_validation', 'view_logic']],
    '/logic/custom-rules/create': [['create_custom_report_validation', 'view_logic']],
    '/logic/custom-rules/edit/:rule_id': [
      ['view_custom_report_validation', 'view_logic'],
      ['view_custom_report_validation', 'create_custom_report_validation', 'view_logic'],
    ],

    // SYSTEM
    '/system': [['any']],
    '/system/monitor': [['watchdog_read']],
    '/system/monitor/overview': [['watchdog_read']],
    '/system/monitor/events': [['view_events', 'view_aggregate_events']],
    '/system/monitor/logs': [['view_logs']],
    '/system/manage': [
      ['view_config'],
      ['view_oauth_scopes'],
      ['view_sso_settings', 'update_sso_settings'],
      ['view_user', 'view_users'],
      ['view_role', 'view_roles'],
      ['view_client', 'view_clients'],
      ['create_entity'],
      ['delete_entity'],
      ['update_own_entity'],
      ['update_all_entities'],
      ['manage_api_token'],
    ],
    '/system/manage/settings': [['view_config'], ['view_oauth_scopes'], ['view_sso_settings', 'update_sso_settings']],
    '/system/manage/users': [['view_user', 'view_users']],
    '/system/manage/teams': [['view_user', 'view_users']],
    '/system/manage/teams/:team_id': [['view_user', 'view_users']],
    '/system/manage/teams/:team_id/users': [['view_user', 'view_users']],
    '/system/manage/teams/:team_id/actions': [['view_user', 'view_users']],
    '/system/manage/teams/:team_id/sharing': [['view_user', 'view_users']],
    '/system/manage/teams/:team_id/info': [['view_user', 'view_users']],
    '/system/manage/roles': [['view_role', 'view_roles']],
    '/system/manage/applications': [['view_client', 'view_clients']],
    '/system/manage/entities': [['create_entity'], ['delete_entity'], ['update_own_entity'], ['update_all_entities']],
    '/system/manage/approvals': [['create_report']],
    '/system/manage/api-keys': [['manage_api_token']],
    '/system/releases': [['any']],
    '/system/releases/new': [['any']],
    '/system/releases/history': [['any']],
    '/system/releases/notes': [['any']],
    '/system/stars': [['any']],
    '/system/stars/diffs': [['any']],
    '/system/stars/benchmarks': [['any']],

    // JOBS
    '/running-jobs': [['jobs_read']],

    // USERS
    '/user/settings': [['view_profile', 'update_profile']],
    '/user/:user_id': [['view_user']],
    '/user/todo': [['view_profile']],

    // DEV
    '/dev/report-template-test': [['dev_mode']],
    '/dev/report-chart-test': [['dev_mode']],
  },

  by_panel: {
    // WORKFLOW
    'workflow-user-list-panel': [['view_users']],

    // EVENTS
    'events-panel': [['view_events']],

    'job-cancel': [['jobs_cancel']],

    // HOME
    'home-todo-panel': [['view_profile']],
    'home-notification-panel': [['view_profile']],
    'home-ingestion-panel': [['any']],
    'home-reports-panel': [['view_reports_list']],
    'home-submission-schedules': [['view_retention_policy']],

    // COLLECTIONS
    'collection-list-panel': [['view_report_collection']],
    'collection-archive-button': [['delete_report_collection']],
    'collection-create-button': [['create_report_collection']],
    'collection-clone-button': [['create_report_collection']],
    'collection-edit-button': [['update_report_collection']],

    // RULESETS
    'rulesets-create-button': [['create_ruleset']],
    'rulesets-update-button': [['update_ruleset']],
    'rulesets-archive-button': [['delete_ruleset']],

    // DATA MAGNETS
    'data-magnets-create-button': [['create_data_magnet']],
    'data-magnets-update-button': [['update_data_magnet']],
    'data-magnets-archive-button': [['delete_data_magnet']],

    // DATA
    'data-archive-button': [['create_data']],
    'data-create-button': [['create_data']],

    // REPORTS DETAIL
    'report-overview-panel': [['view_reports_list']],
    'report-overview-submit-button': [['submit_report']],
    'report-overview-archive-button': [['archive_report']],
    'report-create-button': [['create_report'], ['upload_report'], ['merge_report']],
    'report-archive-report-button': [['archive_report']],
    'report-update-workflow-button': [['update_report', 'view_users']],
    'report-update-visibility-button': [['update_report', 'view_users']],
    'report-export-report-button': [['view_data']],
    'report-edit-report-button': [['update_report']],
    'report-update-collection-button': [['view_report_collection', 'update_report_collection']],
    'report-clone-report-button': [['create_report']],
    'report-view-data-button': [['view_data']],
    'report-comment-form': [['create_comment']],
    'report-update-tags-panel': [['view_report_tag', 'create_report_tag', 'update_report']],
    'report-aggregation-config-panel': [['view_aggregation_config', 'view_users']],
    'report-aggregation-config-save-button': [
      ['view_aggregation_config', 'create_aggregation_config', 'update_aggregation_config', 'view_users'],
    ],

    // REPORT CREATE
    'report-create-create-action': [['create_report']],
    'report-create-upload-action': [['upload_report']],
    'report-create-merge-action': [['merge_report']],
    'report-datamagnet-button': [['view_data_magnets_list']],
    'report-ruleset-button': [['view_rulesets_list']],

    // LOGIC
    'logic-custom-rules-create-button': [['create_custom_report_validation']],
    'logic-custom-rules-update-button': [['update_custom_report_validation']],
    'logic-custom-rules-enable-button': [['update_custom_report_validation']],
    'logic-custom-rules-archive-button': [['delete_custom_report_validation']],

    // SYSTEM
    'system-overview-monitor-panel': [['watchdog_read']],
    'system-overview-events-panel': [['view_events']],
    'system-overview-aggregate-events-link': [['view_events', 'view_aggregate_events']],
    // - ingestors
    'system-ingestors-panel': [['any']],
    'system-ingestion-create-button': [['any']],
    'system-ingestion-delete-button': [['any']],
    // - audit
    'system-audit-activities-filters': [['view_users', 'view_activities']],
    // - settings
    'system-settings-update-button': [['update_config']],
    'system-settings-report-date-ranges-panel': [['view_report_date_range']],
    'system-settings-report-date-ranges-create-button': [['create_report_date_range']],
    'system-settings-report-date-ranges-delete-button': [['delete_report_date_range']],
    // - backups
    'system-backup-schedule-list-panel': [['view_backupschedule']],
    'system-backup-schedule-create-button': [['create_backupschedule']],
    'system-backup-schedule-enable-button': [['modify_backupschedule']],
    'system-backup-schedule-cancel-button': [['create_backupschedule']],
    // - logs
    'system-logs-panel': [['view_logs']],
    // - manage
    'manage-users-list-panel': [['view_users', 'view_user']],
    'manage-users-create-button': [['create_users']],
    'manage-users-update-button': [['update_user']],
    'manage-roles-list-panel': [['view_roles', 'view_role']],
    'manage-roles-create-button': [['create_role', 'view_oauth_scopes']],
    'manage-roles-update-button': [['update_role', 'view_oauth_scopes']],
    'manage-roles-delete-button': [['delete_role']],
    'manage-sso-panel': [['view_sso_settings']],
    'manage-applications-list-panel': [['view_clients', 'view_client']],
    'manage-applications-create-button': [['create_clients']],
    'manage-applications-update-button': [['update_client']],
    'manage-applications-delete-button': [['delete_client']],

    // USERS
    'user-settings-announcements': [['view_profile']],
    'user-settings-activities': [['view_profile']],
    'update-password-profile-button': [['update_profile']],
    'update-password-user-button': [['update_user']],

    // GENERIC LINKS
    'link-to-data': [['view_data']],
    'link-to-report': [['view_report']],
    'link-to-file': [['view_file']],
    'link-to-user': [['view_users']],
    'link-to-ingestion': [['any']],
    'link-to-ruleset': [['view_ruleset']],
    'link-to-data-magnet': [['view_data_magnet']],
  },

  by_calculators: {
    'data-magnets': [['NOT_ONLY_fdsf']],
    rulesets: [['NOT_ONLY_fdsf']],

    'data-config': [['solvency'], ['opr'], ['ifr'], ['le'], ['almm'], ['ifpr'], ['bcar'], ['lr']],

    rules: [['fdsf'], ['testreport']],
    'custom-rules': [['fdsf'], ['testreport']],

    'meta-reports': [['metareport']],
  },

  by_service: {
    dev: [['dev_mode']],

    'report-template-configs': [['template_config']],

    gabriel: [['gabriel_submission']],
    regdata: [['regdata_submission']],
    osca: [['osca_submission']],
    regulators: [['gabriel_submission'], ['osca_submission'], ['regdata_submission']],

    teams_visibility: [['teams_visibility']],

    'data-detail-view': [['dev_mode']],
    'data-explore': [['dev_mode']],
    'report-explore': [['dev_mode']],
    'system-backup': [['dev_mode']],
    'system-token': [['dev_mode']],
    'system-manage-upgrade': [['dev_mode']],
    'aggregation-config': [['dev_mode']],
    'datatable-currency': [['dev_mode']],
    'developer-banner': [['dev_mode']],
    'datatable-search': [['dev_mode']],
    'report-logs': [['dev_mode']], // complex condition, depending on calculation_error (see reportDetail.template.html).
    report_custom_validation_rule: [['report_custom_validation_rule']],
    data_collections: [['data_collections']],
    fireman: [['demo']],
    stars: [['stars_enabled']],
  },

  // configs used in  'Manage System' page for display the permission in checkboxes groups

  // List of normalized permission actions (used to grouped by the permissions by kind dynamically)
  // /!\ The order is important, it's used to sort the permissions in the input select (for UX consistency)
  // ex: 'create_comment' and 'view_comment' are grouped in 'Comment' permission kind
  PERMISSION_ACTIONS: [
    'create',
    'view',
    'update',
    'delete', // common actions
    'archive', // _report
    'merge', // _report
    'upload', // _report
    'sign_off', // _data_configuration, _data_magnet, _report, _ruleset
    'review', // _data_configuration, _report
    'submit', // _report
    'run', // _metareport
    'challenge', // _data
    'validate', // _xbrl
    'revoke', // _oauth_tokens
    'edit', // _retention_policy
    'schedule', // _backup
    'modify', // _backupschedule
  ],

  // List of hard coded Permission Kinds
  PERMISSION_KINDS: {
    OAuth: [
      // groups OAuth tokens, OAuth errors, OAuth scopes permission kinds
      'create_oauth_tokens',
      'revoke_oauth_tokens',
      'view_oauth_errors',
      'view_oauth_scopes',
    ],
    Client: [
      // groups OAuth Client, Clients permission kinds
      'view_client',
      'update_client',
      'delete_client',
      'view_clients',
      'create_clients',
    ],
    Backup: [
      // groups Backup, Backups, Backupschedule permission kinds
      'delete_backup',
      'schedule_backup',
      'view_backups',
      'create_backupschedule',
      'view_backupschedule',
      'modify_backupschedule',
    ],
    User: [
      // groups User, Users permission kinds
      'view_user',
      'update_user',
      'create_users',
      'view_users',
    ],
    Entity: [
      // entity management
      'create_entity',
      'delete_entity',
      'update_all_entities',
      'update_own_entity',
    ],
    Events: [
      // groups Events, System events, Activities, Aggregate events permission kinds
      'view_events',
      'view_system_events',
      'view_activities',
      'view_aggregate_events',
    ],
    Role: [
      // groups Role, Roles permission kinds
      'create_role',
      'view_role',
      'update_role',
      'delete_role',
      'view_roles',
    ],
    'Data configuration': [
      // groups Data configuration, Data configurations permission kinds
      'sign_off_data_configuration',
      'review_data_configuration',
      'create_data_configurations',
      'view_data_configurations',
      'update_data_configurations',
      'delete_data_configurations',
    ],
    Ruleset: [
      // groups Ruleset, Rulesets list permission kinds
      'create_ruleset',
      'view_ruleset',
      'update_ruleset',
      'delete_ruleset',
      'sign_off_ruleset',
      'view_rulesets_list',
    ],
    'Data magnet': [
      // groups Data magnet, Data magnets list permission kinds
      'create_data_magnet',
      'view_data_magnet',
      'update_data_magnet',
      'delete_data_magnet',
      'sign_off_data_magnet',
      'view_data_magnets_list',
    ],
  },
};
