export default [
  // HOME
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/modules/home/home.vue'),
    meta: {
      icon: 'house',
      breadcrumb: [{name: 'Home'}],
    },
  },
  {
    path: '/',
    redirect: {path: '/home'},
  },

  // LOGIN
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/modules/login/login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/modules/login/logout.vue'),
  },

  // DATA
  {
    path: '/data',
    redirect: {path: '/data/overview'},
  },
  {
    path: '/data/overview',
    name: 'Data Overview',
    component: () => import('@/modules/data/batches/dataOverview.vue'),
    meta: {
      icon: 'layers',
      breadcrumb: [{name: 'Data'}],
    },
  },
  {
    path: '/data/create',
    name: 'Data Ingestion',
    component: () => import('@/modules/data/batches/dataCreate.vue'),
    meta: {
      icon: 'layers',
      breadcrumb: [
        {
          name: 'Data',
          to: '/data/overview',
        },
        {
          name: 'Ingestion',
        },
      ],
    },
  },
  {
    path: '/data/decision',
    redirect: {path: '/data/decision/overview'},
  },
  {
    path: '/data/decision/overview',
    name: 'Decision Trees Overview',
    component: () => import('@/modules/data/decisionTrees/decisionTreeOverview.vue'),
    meta: {
      icon: 'layers',
      breadcrumb: [
        {
          name: 'Data',
          to: '/data/overview',
        },
        {
          name: 'Decision Trees',
        },
      ],
    },
  },
  {
    path: '/data/decision/run',
    name: 'Decision Tree Complete',
    component: () => import('@/modules/data/decisionTrees/decisionTreeComplete.vue'),
    meta: {
      icon: 'layers',
      breadcrumb: [
        {
          name: 'Data',
          to: '/data/overview',
        },
        {
          name: 'Decision Trees',
          to: '/data/decision/overview',
        },
        {
          name: 'Run',
        },
      ],
    },
  },

  {
    path: '/data/decision/view/:results_id',
    name: 'Decision Tree Results',
    component: () => import('@/modules/data/decisionTrees/decisionTreeResults.vue'),
    meta: {
      icon: 'layers',
      breadcrumb: [
        {
          name: 'Data',
          to: '/data/overview',
        },
        {
          name: 'Decision Trees',
          to: '/data/decision/overview',
        },
        {
          name: ':results_id',
        },
      ],
    },
  },
  {
    path: '/data/:data_type/:batch_id',
    name: 'Data Detail',
    component: () => import('@/modules/data/batches/dataBatch.vue'),
    meta: {
      icon: 'layers',
      breadcrumb: [
        {
          name: 'Data',
          to: '/data/overview',
        },
        {
          name: ':data_type',
          to: {
            path: '/data/overview',
            query: {
              data_type: ':data_type',
            },
          },
        },
        {
          name: ':batch_id',
        },
      ],
    },
  },
  {
    path: '/data/collection/overview',
    name: 'Data Collections',
    component: () => import('@/modules/data/collections/dataCollectionOverview.vue'),
    meta: {
      icon: 'layers',
      breadcrumb: [
        {
          name: 'Data',
          to: '/data/overview',
        },
        {
          name: 'Data Collections',
        },
      ],
      service: 'data_collections',
    },
  },

  {
    path: '/data/collection/:collection_id',
    name: 'Data Collection Detail',
    component: () => import('@/modules/data/collections/dataCollectionDetail.vue'),
    meta: {
      icon: 'layers',
      breadcrumb: [
        {
          name: 'Data',
          to: '/data/overview',
        },
        {
          name: 'Data Collections',
          to: '/data/collection/overview',
        },
        {
          name: ':collection_id',
        },
      ],
      service: 'data_collections',
    },
  },

  {
    path: '/data/collection/:collection_id/data-view/:view_id/:custom_name',
    name: 'Data View',
    component: () => import('@/modules/data/collections/_components/dataView.vue'),
    meta: {
      icon: 'layers',
      breadcrumb: [
        {
          name: 'Data',
          to: '/data/overview',
        },
        {
          name: 'Data Collections',
          to: '/data/collection/overview',
        },
        {
          name: ':collection_id',
          to: '/data/collection/:collection_id',
        },
        {
          name: ':view_id',
          customName: ':custom_name',
        },
      ],
      service: 'data_collections',
    },
  },

  // REPORT
  {
    path: '/report',
    redirect: {path: '/report/overview'},
  },
  {
    path: '/report/overview',
    name: 'Reports Overview',
    component: () => import('@/modules/reporting/reports/reportOverview.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [{name: 'Report'}],
    },
  },
  {
    path: '/report/create',
    name: 'Report Creation',
    component: () => import('@/modules/reporting/reports/reportCreate.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Create',
        },
      ],
    },
  },
  {
    path: '/report/collection/overview',
    name: 'Report Collections',
    component: () => import('@/modules/reporting/collections/collectionOverview.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Collections',
        },
      ],
    },
  },
  {
    path: '/report/collection/create',
    name: 'Create Report Collection',
    component: () => import('@/modules/reporting/collections/collectionCreate.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Collections',
          to: '/report/collection/overview',
        },
        {
          name: 'Create',
        },
      ],
    },
  },
  {
    path: '/report/collection/:collection_id',
    name: 'Report Collection Detail',
    component: () => import('@/modules/reporting/collections/collection.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Collections',
          to: '/report/collection/overview',
        },
        {
          name: ':collection_id',
        },
      ],
    },
  },
  {
    // for v1/v2 switch compatibility
    path: '/report-cell-compare',
    name: 'Cell Comparison Tool',
    component: () => import('@/modules/reporting/comparison/cellComparison.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Cell Comparison',
        },
      ],
    },
  },
  {
    path: '/report/submissions',
    name: 'Regulator Submissions',
    component: () => import('@/modules/reporting/submissions/submissionOverview.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Regulator Submissions',
        },
      ],
    },
  },
  {
    path: '/report/:report_id',
    name: 'Report Detail',
    component: () => import('@/modules/reporting/reports/report.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: ':report_id',
        },
      ],
    },
  },
  {
    path: '/report/:report_id/page/:page',
    name: 'Report Details',
    component: () => import('@/modules/reporting/reports/report.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: ':report_id',
        },
      ],
    },
  },
  {
    path: '/report/custom/overview',
    name: 'Custom Report Types Overview',
    component: () => import('@/modules/reporting/custom/customReportOverview.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Custom',
        },
      ],
      service: 'meta-reports',
    },
  },
  {
    path: '/report/custom/create',
    name: 'Create Custom Report Type',
    component: () => import('@/modules/reporting/custom/customReportCreate.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Custom',
          to: '/report/custom/overview',
        },
        {
          name: 'Create',
        },
      ],
      service: 'meta-reports',
    },
  },
  {
    path: '/report/custom/:report_id',
    name: 'Edit Custom Report Type',
    component: () => import('@/modules/reporting/custom/customReportCreate.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Custom',
          to: '/report/custom/overview',
        },
        {
          name: ':report_id',
        },
      ],
      service: 'meta-reports',
    },
  },
  {
    path: '/report-compare',
    name: 'Report Compare Overview',
    component: () => import('@/modules/reporting/comparison/reportComparison.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Compare',
        },
      ],
    },
  },
  {
    path: '/report-compare/page/:page',
    name: 'Report Compare',
    component: () => import('@/modules/reporting/comparison/reportComparison.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Compare',
        },
      ],
    },
  },
  {
    path: '/report/data-magnet/overview',
    name: 'Data Magnets',
    component: () => import('@/modules/reporting/dataConfigurations/dataMagnets/dataMagnetOverview.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Data Magnets',
        },
      ],
      calculators: 'data-magnets',
    },
  },
  {
    path: '/report/data-magnet/create',
    name: 'Create Data Magnet',
    component: () => import('@/modules/reporting/dataConfigurations/dataMagnets/dataMagnetCreate.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Data Magnets',
          to: '/report/data-magnet/overview',
        },
        {
          name: 'Create',
        },
      ],
      calculators: 'data-magnets',
    },
  },
  {
    path: '/report/data-magnet/:data_magnet_id',
    name: 'Data Magnet Details',
    component: () => import('@/modules/reporting/dataConfigurations/dataMagnets/dataMagnet.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Data Magnets',
          to: '/report/data-magnet/overview',
        },
        {
          name: ':data_magnet_id',
        },
      ],
      calculators: 'data-magnets',
    },
  },
  {
    path: '/report/ruleset/overview',
    name: 'Rulesets',
    component: () => import('@/modules/reporting/dataConfigurations/rulesets/rulesetOverview.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Rulesets',
        },
      ],
      calculators: 'rulesets',
    },
  },
  {
    path: '/report/ruleset/create',
    name: 'Create Ruleset',
    component: () => import('@/modules/reporting/dataConfigurations/rulesets/rulesetCreate.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Rulesets',
          to: '/report/ruleset/overview',
        },
        {
          name: 'Create',
        },
      ],
      calculators: 'rulesets',
    },
  },
  {
    path: '/report/ruleset/:ruleset_id',
    name: 'Ruleset Details',
    component: () => import('@/modules/reporting/dataConfigurations/rulesets/ruleset.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Rulesets',
          to: '/report/ruleset/overview',
        },
        {
          name: ':ruleset_id',
        },
      ],
      calculators: 'rulesets',
    },
  },

  // Redirect pluralised 'rulesets' to 'ruleset'
  {
    path: '/report/rulesets/overview',
    redirect: {path: '/report/ruleset/overview'},
  },
  {
    path: '/report/rulesets/create',
    redirect: {path: '/report/ruleset/create'},
  },
  {
    path: '/report/rulesets/:ruleset_id',
    redirect: {name: 'Ruleset Details'},
  },

  {
    path: '/report/template/overview',
    name: 'Report Templates',
    component: () => import('@/modules/reporting/templates/templateOverview.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Templates',
        },
      ],
    },
  },
  {
    path: '/report/template/create',
    name: 'Create Report Templates',
    component: () => import('@/modules/reporting/templates/templateCreate.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Templates',
          to: '/report/template/overview',
        },
        {
          name: 'Create',
        },
      ],
      service: 'report-template-configs',
    },
  },
  // CUSTOM VALIDATIONS
  {
    path: '/report/validations',
    redirect: {path: '/report/validations/overview'},
  },
  {
    path: '/report/validations/overview',
    name: 'Custom Validations',
    component: () => import('@/modules/reporting/validations/validationsOverviewPage.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Custom Validations',
        },
      ],
      service: 'report_custom_validation_rule',
    },
  },
  {
    path: '/report/validations/create',
    name: 'Create Custom Validations',
    component: () => import('@/modules/reporting/validations/createEditValidationPage.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Custom Validations',
          to: '/report/validations/overview',
        },
        {
          name: 'Create',
        },
      ],
      service: 'report_custom_validation_rule',
    },
  },
  {
    path: '/report/validations/:custom_validation_id',
    name: 'Custom Validation Review',
    component: () => import('@/modules/reporting/validations/validationViewPage.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Custom Validations',
          to: '/report/validations/overview',
        },
        {
          name: ':custom_validation_id',
        },
      ],
      service: 'report_custom_validation_rule',
    },
  },
  {
    path: '/report/validations/:custom_validation_id/edit',
    name: 'Edit Custom Validation',
    component: () => import('@/modules/reporting/validations/createEditValidationPage.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Custom Validations',
          to: '/report/validations/overview',
        },
        {
          name: 'Edit Custom Validation',
        },
      ],
      service: 'report_custom_validation_rule',
    },
  },
  {
    path: '/report/validations/:custom_validation_id/duplicate',
    name: 'Duplicate Custom Validation',
    component: () => import('@/modules/reporting/validations/createEditValidationPage.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Custom Validations',
          to: '/report/validations/overview',
        },
        {
          name: 'Duplicate Custom Validation',
        },
      ],
    },
    service: 'report_custom_validation_rule',
  },
  {
    path: '/report/template/:template_id',
    name: 'Report Template Detail',
    component: () => import('@/modules/reporting/templates/template.vue'),
    meta: {
      icon: 'clipboard',
      breadcrumb: [
        {
          name: 'Report',
          to: '/report/overview',
        },
        {
          name: 'Templates',
          to: '/report/template/overview',
        },
        {
          name: ':template_id',
        },
      ],
      service: 'report-template-configs',
    },
  },

  // REGINA
  {
    path: '/regina/analysis',
    redirect: {path: '/regina/analyser'},
  },
  {
    path: '/regina',
    redirect: {name: 'Analyser'},
    component: () => import('@/modules/regina/regina.vue'),
    children: [
      {
        path: '/regina/analyser',
        name: 'Analyser',
        component: () => import('@/modules/regina/_components/analyzer.vue'),
        meta: {
          icon: 'crown',
          breadcrumb: [
            {
              name: 'Regina',
              to: '/regina/analyser',
            },
            {
              name: 'Analyser',
            },
          ],
        },
      },
      {
        path: '/regina/product-examples',
        name: 'Product Examples',
        component: () => import('@/modules/regina/_components/productExamples.vue'),
        meta: {
          icon: 'crown',
          breadcrumb: [
            {
              name: 'Regina',
              to: '/regina/analyser',
            },
            {
              name: 'Product Examples',
            },
          ],
        },
      },
      {
        path: '/regina/drill-up',
        name: 'Drill-Up',
        component: () => import('@/modules/regina/_components/drillUp.vue'),
        meta: {
          icon: 'crown',
          breadcrumb: [
            {
              name: 'Regina',
              to: '/regina/analyser',
            },
            {
              name: 'Drill-Up',
            },
          ],
        },
      },
      {
        path: '/regina/what-if',
        name: 'What-If',
        component: () => import('@/modules/regina/_components/whatIf.vue'),
        meta: {
          icon: 'crown',
          breadcrumb: [
            {
              name: 'Regina',
              to: '/regina/analyser',
            },
            {
              name: 'What-If',
            },
          ],
        },
      },
      {
        path: '/regina/fireman',
        name: 'Fireman',
        component: () => import('@/modules/regina/_components/fireman.vue'),
        meta: {
          icon: 'crown',
          breadcrumb: [
            {
              name: 'Regina',
              to: '/regina/analyser',
            },
            {
              name: 'Fireman',
            },
          ],
          service: 'fireman',
        },
      },
    ],
  },

  // LOGIC
  {
    path: '/logic/overview',
    name: 'Calculation Logic',
    component: () => import('@/modules/logic/logic.vue'),
    meta: {
      icon: 'bonding',
      breadcrumb: [{name: 'Logic'}],
    },
  },
  {
    path: '/logic',
    redirect: {path: '/logic/overview'},
  },
  {
    path: '/logic/rules',
    name: 'Validation Rules',
    component: () => import('@/modules/logic/rules.vue'),
    meta: {
      icon: 'bonding',
      breadcrumb: [
        {
          name: 'Logic',
          to: '/logic/overview',
        },
        {
          name: 'Validation Rules',
        },
      ],
    },
  },
  {
    path: '/logic/custom-rules',
    name: 'Custom Validation Rules',
    component: () => import('@/modules/logic/customRules/customRules.vue'),
    meta: {
      icon: 'bonding',
      breadcrumb: [
        {
          name: 'Logic',
          to: '/logic/overview',
        },
        {
          name: 'Custom Validation Rules',
        },
      ],
    },
  },
  {
    path: '/logic/custom-rules/create',
    name: 'Create Custom Validation Rule',
    component: () => import('@/modules/logic/customRules/createViewCustomRule.vue'),
    meta: {
      icon: 'bonding',
      breadcrumb: [
        {
          name: 'Logic',
          to: '/logic/overview',
        },
        {
          name: 'Custom Validation Rules',
          to: '/logic/custom-rules',
        },
        {
          name: 'Create',
        },
      ],
    },
  },
  {
    path: '/logic/custom-rules/edit/:rule_id',
    name: 'Custom Validation Rule',
    component: () => import('@/modules/logic/customRules/createViewCustomRule.vue'),
    meta: {
      icon: 'bonding',
      breadcrumb: [
        {
          name: 'Logic',
          to: '/logic/overview',
        },
        {
          name: 'Custom Rules',
          to: '/logic/custom-rules',
        },
        {
          name: ':rule_id',
        },
      ],
    },
  },

  // SYSTEM

  {
    path: '/system/',
    redirect: {path: '/system/monitor/overview'},
  },
  {
    path: '/system/monitor',
    redirect: {path: '/system/monitor/overview'},
  },
  // redirect old changelog url to new releases url
  {
    path: '/system/monitor/changelog',
    redirect: {path: '/system/releases/notes'},
  },
  {
    path: '/system/releases',
    redirect: {path: '/system/releases/new'},
  },
  {
    path: '/system/stars',
    redirect: {path: '/system/stars/benchmarks'},
  },
  {
    path: '/system',
    name: 'System',
    component: () => import('@/modules/system/system.vue'),
    meta: {
      icon: 'options',
      breadcrumb: [
        {
          name: 'System',
        },
      ],
    },
    children: [
      {
        path: '/system/monitor',
        name: 'Monitor',
        component: () => import('@/modules/system/systemMonitor.vue'),
        meta: {
          icon: 'options',
          breadcrumb: [
            {
              name: 'System',
            },
          ],
        },
        children: [
          {
            path: '/system/monitor/overview',
            name: 'Overview',
            component: () => import('@/modules/system/_components/systemOverview.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Monitor',
                  to: '/system/monitor',
                },
                {
                  name: 'Overview',
                },
              ],
            },
          },
          {
            path: '/system/monitor/logs',
            name: 'Logs',
            component: () => import('@/modules/system/_components/systemLogs.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Monitor',
                  to: '/system/monitor',
                },
                {
                  name: 'Logs',
                },
              ],
            },
          },
          {
            path: '/system/monitor/events',
            name: 'Events',
            component: () => import('@/modules/system/_components/systemEvents.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Monitor',
                  to: '/system/monitor',
                },
                {
                  name: 'Events',
                },
              ],
            },
          },
        ],
      },

      {
        path: '/system/manage',
        name: 'Manage',
        component: () => import('@/modules/system/systemManage.vue'),
        meta: {
          icon: 'options',
          breadcrumb: [
            {
              name: 'System',
            },
          ],
        },
        children: [
          {
            path: '/system/manage/settings',
            name: 'Settings',
            component: () => import('@/modules/system/_components/manageSettings.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Settings',
                },
              ],
            },
          },
          {
            path: '/system/manage/users',
            name: 'Users',
            component: () => import('@/modules/system/_components/manageUsers.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Users',
                },
              ],
            },
          },
          {
            path: '/system/manage/teams',
            name: 'Teams',
            component: () => import('@/modules/system/_components/manageTeams.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Teams',
                },
              ],
            },
          },
          {
            path: '/system/manage/roles',
            name: 'Roles',
            component: () => import('@/modules/system/_components/manageRoles.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Roles',
                },
              ],
            },
          },
          {
            path: '/system/manage/applications',
            name: 'Applications',
            component: () => import('@/modules/system/_components/manageApplications.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Applications',
                },
              ],
            },
          },
          {
            path: '/system/manage/entities',
            name: 'Entities',
            component: () => import('@/modules/system/_components/manageEntities.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Entities',
                },
              ],
            },
          },
          {
            path: '/system/manage/approvals',
            name: 'Approvals',
            component: () => import('@/modules/system/_components/manageApprovals.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Approvals',
                },
              ],
            },
          },
          {
            path: '/system/manage/api-keys',
            name: 'API Keys',
            component: () => import('@/modules/system/_components/manageApiKeys.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'API Keys',
                },
              ],
            },
          },
        ],
      },

      {
        path: '/system/manage/teams/:team_id',
        name: 'Team',
        component: () => import('@/modules/system/systemTeamDetail.vue'),
        meta: {
          icon: 'options',
          breadcrumb: [
            {
              name: 'System',
              to: '/system',
            },
            {
              name: 'Manage',
              to: '/system/manage',
            },
            {
              name: 'Teams',
              to: '/system/manage/teams',
            },
            {
              name: ':team_id',
            },
          ],
        },
        children: [
          {
            path: '/system/manage/teams/:team_id/users',
            name: 'Team Users',
            component: () => import('@/modules/system/_components/systemTeamUsers.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Teams',
                  to: '/system/manage/teams',
                },
                {
                  name: ':team_id',
                },
                {
                  name: 'Users',
                },
              ],
            },
          },
          {
            path: '/system/manage/teams/:team_id/actions',
            name: 'Team Actions',
            component: () => import('@/modules/system/_components/systemTeamActions.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Teams',
                  to: '/system/manage/teams',
                },
                {
                  name: ':team_id',
                },
                {
                  name: 'Users',
                },
              ],
            },
          },
          {
            path: '/system/manage/teams/:team_id/sharing',
            name: 'Team Sharing',
            component: () => import('@/modules/system/_components/systemTeamSharing.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Teams',
                  to: '/system/manage/teams',
                },
                {
                  name: ':team_id',
                },
                {
                  name: 'Sharing',
                },
              ],
            },
          },
          {
            path: '/system/manage/teams/:team_id/info',
            name: 'Additional Information',
            component: () => import('@/modules/system/_components/systemTeamInfo.vue'),
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Manage',
                  to: '/system/manage',
                },
                {
                  name: 'Teams',
                  to: '/system/manage/teams',
                },
                {
                  name: ':team_id',
                },
                {
                  name: 'Additional Information',
                },
              ],
            },
          },
        ],
      },

      {
        path: '/system/releases',
        name: 'Updates & Releases',
        component: () => import('@/modules/system/systemUpdates.vue'),
        meta: {
          icon: 'options',
          breadcrumb: [
            {
              name: 'System',
            },
          ],
        },
        children: [
          {
            path: '/system/releases/new',
            name: 'New Releases',
            component: () => import('@/modules/system/_components/systemReleases.vue'),
            props: true,
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Updates & Releases',
                },
              ],
            },
          },
          {
            path: '/system/releases/history',
            name: 'Historical Releases',
            component: () => import('@/modules/system/_components/historicalReleases.vue'),
            props: true,
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Updates & Releases',
                },
              ],
            },
          },
          {
            path: '/system/releases/notes',
            name: 'Release Notes',
            component: () => import('@/modules/system/_components/systemChangelog.vue'),
            props: true,
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'System',
                  to: '/system',
                },
                {
                  name: 'Updates & Releases',
                  to: '/system/releases',
                },
                {
                  name: 'Release Notes',
                },
              ],
            },
          },
        ],
      },
      {
        path: '/system/stars',
        name: 'STARS',
        component: () => import('@/modules/system/systemStars.vue'),
        meta: {
          icon: 'options',
          breadcrumb: [
            {
              name: 'STARS',
            },
          ],
          service: 'stars',
        },
        children: [
          {
            path: '/system/stars/diffs',
            name: 'Differences',
            component: () => import('@/modules/system/_components/systemStarsDiffs.vue'),
            props: true,
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'STARS',
                  to: '/system/stars',
                },
                {
                  name: 'Differences',
                },
              ],
              service: 'stars',
            },
          },
          {
            path: '/system/stars/benchmarks',
            name: 'Benchmarks',
            component: () => import('@/modules/system/_components/systemStarsBenchmarks.vue'),
            props: true,
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'STARS',
                  to: '/system/stars',
                },
                {
                  name: 'Benchmarks',
                },
              ],
              service: 'stars',
            },
          },
          {
            path: '/system/stars/benchmarks',
            name: 'Benchmarks',
            component: () => import('@/modules/system/_components/systemStarsBenchmarks.vue'),
            props: true,
            meta: {
              icon: 'options',
              breadcrumb: [
                {
                  name: 'STARS',
                  to: '/system/stars',
                },
                {
                  name: 'Benchmarks',
                },
              ],
              service: 'stars',
            },
          },
        ],
      },
    ],
  },

  // JOBS
  {
    path: '/running-jobs', // TODO : when migration is done, replace 'running-jobs' by 'jobs'
    name: 'Running Jobs',
    component: () => import('@/modules/jobs/jobs.vue'),
    meta: {
      icon: 'guage',
      breadcrumb: [
        {
          name: 'Running Jobs',
        },
      ],
    },
  },

  // USER
  {
    path: '/user/settings',
    name: 'User Settings',
    component: () => import('@/modules/user/user.vue'),
    meta: {
      icon: 'user',
      breadcrumb: [
        {
          name: 'User',
        },
        {
          name: 'Settings',
        },
      ],
    },
  },
  {
    path: '/user/:user_id',
    name: 'User View',
    component: () => import('@/modules/user/user.vue'),
    props: true,
    meta: {
      icon: 'user',
      breadcrumb: [
        {
          name: 'User',
        },
        {
          name: ':user_id',
        },
      ],
    },
  },

  // DEV PAGES
  {
    path: '/dev/report-template-test',
    name: 'DEV Report Template Test',
    component: () => import('@/modules/reporting/templateGenerator/reportTemplateTest.vue'),
    meta: {
      icon: 'lab',
      breadcrumb: [
        {
          name: 'DEV',
        },
        {
          name: 'Report Template Test',
        },
      ],
    },
  },
  {
    path: '/dev/report-chart-test',
    name: 'DEV Report Chart Test',
    component: () => import('@/modules/reporting/templateGenerator/reportChartTest.vue'),
    meta: {
      icon: 'lab',
      breadcrumb: [
        {
          name: 'DEV',
        },
        {
          name: 'Report Chart Test',
        },
      ],
    },
  },

  // Redirect all other routes home
  {
    path: '/*',
    redirect: {path: '/home'},
  },
];
